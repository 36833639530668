import React, { useState, useEffect } from "react";
import { graphql } from "gatsby";
import { WPBlogPost } from "../../components/blogpost";
import { OWNEDMEDIA_WORDPRESS_URL } from "../../AppConst";
import { RedirectToErrorPage } from "../../utlis/404Util";
import { ConnectionFailed } from "../../components/connection_failed";

const fetchPost = async (author, slug, setPost, setIsLoading, setHasError) => {
  setIsLoading(true);

  fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/users/?slug=${author}`)
  .then(response => {return response.json()})
  .then(userData => {
    if(!userData.length) return RedirectToErrorPage(window);

    fetch(`${OWNEDMEDIA_WORDPRESS_URL}/wp-json/wp/v2/posts/?_embed&author=${userData[0].id}&slug=${slug}`)
    .then(response => {return response.json()})
    .then(jsonData => {

      if(!jsonData.length) return RedirectToErrorPage(window);

      const postTags = jsonData[0]._embedded["wp:term"][1].map((value, index) => {
        return value.name
      })

      const postData = {
        body: jsonData[0].content.rendered,
        tableOfContents: [],
        frontmatter: {
          title: jsonData[0].title.rendered,
          date: jsonData[0].date,
          description: jsonData[0].excerpt.rendered,
          category: jsonData[0]._embedded["wp:term"][0][0].slug,
          author: jsonData[0]._embedded.author[0].name,
          tags: postTags,
          slug: jsonData[0].slug,
          thumbnail: jsonData[0]._embedded["wp:featuredmedia"][0].source_url,
        },
      }
      setPost(postData);
      setIsLoading(false)
    })
    .catch((error) => {
      setHasError(true);
    });
  })
  .catch((error) => {
    setHasError(true);
  });
};

export default function BlogTemplate({ params, data }) {
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [post, setPost] = useState(null);
  const author = params.author;
  const slug = params.slug;

  if(!author || !slug) return null;

  useEffect(() => {
    fetchPost(author, slug, setPost, setIsLoading, setHasError)
  }, [params]);

  if (hasError) return <ConnectionFailed />

  return (
    <WPBlogPost data={data} postData={post} isLoading={isLoading} />
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        member {
          name
          position
          pathname
          twitter_url
          filename
        }
      }
    }
    banner10IW: file(relativePath: { eq: "images/10-innovation-web-banner.png"}) {
      childImageSharp {
        fluid(maxWidth: 288, maxHeight: 288) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    },
    banner01EC: file(relativePath: { eq: "images/01engineercamp_banner_new.png"}) {
      childImageSharp {
        fluid(maxWidth: 288, maxHeight: 288) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    },
    banner01ECtoC: file(relativePath: { eq: "images/01ec_to_c_banner.png"}) {
      childImageSharp {
        fluid(maxWidth: 288, maxHeight: 288) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    },
    bannerNEC: file(relativePath: { eq: "images/next-engineer-camp-banner.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 288, maxHeight: 288) {
          ...GatsbyImageSharpFluid_withWebp
          ...GatsbyImageSharpFluidLimitPresentationSize
        }
      }
    },
  }
`