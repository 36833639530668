import React from "react"
import styled from "styled-components"

/**
 *@ Materials
 */
import { BlogCategoryColors, BlogCategoriesJa, RESPONSIVE_STYLES } from '../../AppConst'

/**
 *@ Elements & Styles
 */
const Node = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    gap: 16px;
  }
`

const Head = styled.div`
  display: flex;
  justify-content: space-between;
`

const Category = styled.div<{ categoryColor: string }>`
  position: relative;
  margin: 0;
  padding-left: 18px;

  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.8px;

  &::before {
    width: 14px;
    height: 14px;
    background-color: ${({ categoryColor }) => categoryColor};
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;

    @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
      width: 10px;
      height: 10px;
    }
  }
`
const ArticleTitle = styled.h1`
  margin: 0;
  padding: 0;
  color: #222;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 48px;
  letter-spacing: 1.6px;
  // text-transform: capitalize;

  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 24px;
    font-weight: 700;
    line-height: 36px;
    letter-spacing: 1.2px;
  }
  `

const Date = styled.time`
  color: #7A7A7A;
  text-align: right;

  font-family: Righteous;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 1.6px;
  text-transform: uppercase;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
  }
`

/**
 *@ Types
 */
type Props = {
  category: string
  title: string
  tags: string[];
  slug: string
  date: string
}
/**
 *@ ReactComponent
 */
export const ArticleHeading: React.FC<Props> = props => {
  return (
    <Node>
      <Head>
        <Category categoryColor={BlogCategoryColors[props.category]}>
          {BlogCategoriesJa[props.category]}
        </Category>
        <Date dateTime={props.date.replace(/\./g, '-')}>{props.date}</Date>
      </Head>
      <ArticleTitle>{props.title}</ArticleTitle>
    </Node>
  )
}
