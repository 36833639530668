import React, { useEffect } from "react"
import styled from "styled-components"
import Layout from "../../template/Layout"
import Head from "../../head";
import { RESPONSIVE_STYLES, WRAPPER_STYLES } from "../../AppConst"
import Button from "../common/RecruitmentButton"

/**
 * Styles
 */
const Container = styled.div`
  ${WRAPPER_STYLES}
  width: 500px;
  text-align: center;
  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
    width: 90%;
  }
  & .connection-failed-title {
    font-size: 3.0rem;
    font-family: 'futura-pt';
    font-style: italic;
    letter-spacing: 3.15px;
    font-weight: 500px;
    margin-top: 120px;
    margin-bottom: 66px;
    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      font-size: 2.0rem;
      font-family: 'futura-pt';
      font-style: italic;
      letter-spacing: 2.37px;
      font-weight: 500px;
    }
  }
  & .top-link-button {
    margin-top: 80px;
    margin-bottom: 100px;
    @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px){
      width: 90%;
      max-width: 335px;
      height: 40px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`

/**
 * Component
 */
export const ConnectionFailed: React.FC = () => {

  useEffect(() => {
    const loading = document.getElementById('loading')
    if (loading === undefined || loading === null) return
    loading.style.display = 'none';
  }, [])

  return (
    <Layout sidebar={false}>
      <Head
        title="データの取得に失敗しました | ネクストライブ株式会社"
        description=""
      />
      <Container>
        <div className="connection-failed-title">データの取得に失敗しました</div>
        <div className='top-link-button'>
          <Button text='トップページへ' url={'/'}/>
        </div>
      </Container>
    </Layout>
  )
}