import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { RESPONSIVE_STYLES } from "../../AppConst"

/**
 * Elements & Styles
 */
const Wrapper = styled(Link)`
  width: 100%;
  height: 50px;
  border: solid 2px #000000;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &:hover {
    /* 仮で設定 */
    opacity: 0.6;
  }
  &:disabled {
    /* 仮で設定 */
    opacity: 0.6;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    height: 40px;
  }
`

const ButtonWrapper = styled.button`
  width: 100%;
  height: 50px;
  border: solid 2px #000000;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  box-sizing: border-box;
  text-decoration: none;
  cursor: pointer;
  &:focus {
    outline: 0;
  }
  &:hover {
    /* 仮で設定 */
    opacity: 0.6;
  }
  &:disabled {
    /* 仮で設定 */
    opacity: 0.6;
  }
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 100%;
    height: 40px;
  }
`

const ImgWrapper = styled.div`
  width: 80px;
  height: 21px;
  position: absolute;
  right: 0;
  margin-right: 20px;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    width: 56px;
    height: 13px;
    margin-right: 10px;
  }
`

const Text = styled.span`
  font-family: "futura-pt", "a-otf-midashi-go-mb31-pr6n";
  font-weight: bold;
  font-size: 1.8rem;
  letter-spacing: 1.8px;
  color: #000000;
  @media (max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    font-size: 1.2rem;
    letter-spacing: 1.2px;
  }
`

/**
 * Props
 */
type Options = {
  text?: string
  type?: "button" | "submit" | "reset"
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void
  disabled?: boolean
  url?: string
}

type Props = Partial<Options>

/**
 * Component
 */
const RecruitmentButton: React.FC<Props> = props => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "images/icon_arrow_right@2x.png" }) {
        childImageSharp {
          fluid(maxWidth: 80, maxHeight: 21) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  return (
    <>
      {props.url ? (
        <Wrapper
          type={props.type}
          onClick={props.onClick}
          disabled={props.disabled}
          to={props.url}
        >
          <Text>{props.text}</Text>
          <ImgWrapper>
            <Img fluid={data.file.childImageSharp.fluid} alt="" />
          </ImgWrapper>
        </Wrapper>
      ) : (
        <ButtonWrapper
          type={props.type}
          onClick={props.onClick}
          disabled={props.disabled}
        >
          <Text>{props.text}</Text>
          <ImgWrapper>
            <Img fluid={data.file.childImageSharp.fluid} alt="" />
          </ImgWrapper>
        </ButtonWrapper>
      )}
    </>
  )
}

export default RecruitmentButton
