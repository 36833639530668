import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

/**
 *@ Elements & Styles
 */
const Node = styled.div`
  display: flex;
  justify-content: row;
  align-items: center;
  gap: 16px;
`
const IconWrapper = styled.div`
  width: 80px;
  height: 80px;
  border-radius: 50%;

  & Img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }
`
const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  > span {
    &:nth-child(1) {
      color: #222;

      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 21px;
      letter-spacing: 0.7px;
    }
    &:nth-child(2) {
      color: #222;

      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: 30px;
      letter-spacing: 1px;
    }
  }
`

/**
 *@ Types
 */
type Props = {
  avatar: string
  author: string
  position: string
  pathname: string
  date?: string
}

/**
 *@ ReactComponent
 */
export const Author: React.FC<Props> = props => {
  return (
    <Node>
      <IconWrapper>
        <Link to={`/member/${props.pathname}`}>
          <img src={`/images/${props.avatar}`} />
        </Link>
      </IconWrapper>
      <TextWrapper>
        <span>{props.position}</span>
        <span>{props.author}</span>
      </TextWrapper>
    </Node>
  )
}
