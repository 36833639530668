import React from "react"
import styled from "styled-components"

import IconTwitter from "../../images/icon_twitter.svg"
import IconFacebook from "../../images/icon_facebook.svg"
import { RESPONSIVE_STYLES } from "../../AppConst"

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 48px 0 0;
  padding: 16px 24px 0;
  border-top: 1px solid #222;
  box-sizing: border-box;

  @media(max-width: ${RESPONSIVE_STYLES.TB_MAX_WIDTH}px) {
    padding: 16px 16px 0;
  }
`

const Remark = styled.span`
  flex: 1;

  color: #222;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.8px;
`

const Link = styled.a`
`

const Icons = styled.div`
  display: flex;
  gap: 16px;
`

const Icon = styled.img`
  width: 48px;
  transition: .3s;
  &:hover {
    opacity: .6;
  }
`

type Props = {
  title: string
  url: string
}

export const SNSShare: React.VFC<Props> = ({title, url}) => {
  return (
    <Wrapper>
      <Remark>この記事をシェアする</Remark>
      <Icons>
        <Link
          href={`https://twitter.com/intent/tweet?text=${encodeURI(title)}&url=${encodeURI(url)}`}
          target="_blank">
          <Icon src={IconTwitter} alt={"Twitter"} />
        </Link>
        <Link
          href={`https://www.facebook.com/share.php?u=${url}`}
          target="_blank">
          <Icon src={IconFacebook} alt={"Facebook"} />
        </Link>
      </Icons>
    </Wrapper>
  )
}